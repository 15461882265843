/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components
import { ReactComponent as Logo } from "../../assets/img/logo.svg";

export default function ClienteNav(props) {
  
  return (
    <>
      <nav className="top-0 a z-50 w-full flex flex-wrap items-center justify-between px-2  navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              <Logo width="100%" height="50"/>
            </Link>
          </div>
          <div >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <span >
                  <a className="bg-blueGray-800 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 ease-linear transition-all duration-150"
                  type="button" href="/views/index.js"> <i className="fa fa-reply"></i> Voltar</a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
