import React from "react";
import { Link } from "react-router-dom";

// components

import ClienteNav from "components/Navbars/clientesnav.js";
import Footer from "components/Footers/Footer.js";

export default function Landing() {
  return (
    <>
      <ClienteNav/>
      <main > 
        <br/>
        <br/>
        <br/>
        <br/>
        <section className="pt-20 pb-48 ">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-blueGray-500">Selecione seu sistema:</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500"> </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <a href="https://www.brst.com.br/cliente/dashboard/login.html">
                    <div className=" brfinanas relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
                      <div  className="rounded-t mb-0 px-6 py-6">
                        <div className="text-center mb-3">
                          <img  alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"src={require("assets/img/finançass.png").default} />
                          <h6 className="letra text-sm font-bold text-3xl ">
                            BrFinanças
                            </h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-6/12  lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <a href="https://www.brst.com.br/cliente/painel_armazem/">
                    <div className=" brarmazem relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
                      <div  className="rounded-t mb-0 px-6 py-6">
                        <div className="text-center mb-3">
                          <img  alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"src={require("assets/img/Silovirtual.png").default} />
                          <h6 className="letra text-sm font-bold text-3xl ">
                            BrArmazém
                              </h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-6/12  lg:mb-0 mb-12 px-4"><div className="px-6"> </div> </div>
              <div className="w-full md:w-6/12  lg:mb-0 mb-12 px-4"> <div className="px-6"></div> </div>
            </div>
          </div>
        </section>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" target="_blank" ></link>
        <a 
          id="zapzap"
          href="https://wa.me/556699984060?text=Olá, estou entrando em contato pelo site BRST, gostaria de informações sobre sistemas"
          style={{ position: "fixed", width: "60px", height: "60px", bottom: "65px", right: "27px", backgroundColor: "#25d366",color: "floralwhite", borderRadius: "50px",
          textAlign:"center", fontSize:"30px", boxShadow: "1px 1px 2px #888", zIndex:"1000"}}>
          <i style={{ marginTop: 16 }} className="fa fa-whatsapp"></i>
        </a>
      </main>
      <Footer />
    </>
  );

}
