import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts
import Safra from "views/safra.js"
import Fazendas from "views/ramos/Fazendas.js"
import Revenda from "views/ramos/Revenda"
import Comercializacao from "views/ramos/Comercializacao"
import Industrias from "views/ramos/Industrias"
import Logistica from "views/ramos/Logistica"
import Solucoes from "views/ramos/Solucoes"
import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/areacliente" exact component={Landing} />
      <Route path="/safra" exact component={Safra} />
      <Route path="/fazendas" exact component={Fazendas} />
      <Route path="/comercializacao" exact component={Comercializacao} />
      <Route path="/revenda" exact component={Revenda} />
      <Route path="/industrias" exact component={Industrias} />
      <Route path="/logistica" exact component={Logistica} />
      <Route path="/solucoes" exact component={Solucoes} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
