import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Profile() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://th.bing.com/th/id/R.1a49523466d2ce74af385736b09b9769?rik=yPLjTxFMtuu4yw&pid=ImgRaw&r=0')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("assets/img/fazenda.png").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  Silos e Armazéns de Grãos
                  </h3>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <div className="text-align: left text-blueGray-400 font-bold uppercase">
                      BrSafra
                      </div>
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        BrSafra é o Software mais completo e intuitivo do mercado, com módulos de precisão e controle de talhões,
                        variedades, aplicações, maquinários, RH, e muito mais ferramentas ele conta além de tudo com painéis de indicação e
                        controle, e pode ser integrado a qualquer outro site da BRST, promovendo agilidade, mobilidade, e efetividade em sua 
                        propriedade, não perca mais tempo e dinheiro com soluções ineficientes, opte por uma “máquina” completa de gerenciamento 
                        e produtividade, além de tudo isso o BrSafra é totalmente desenvolvido em cima de pesquisas, e opiniões de usuários, com
                        isso ele apresenta um índice de satisfação de 100%, atendendo todos os usuários de maneira eficiente e ágil. Adquira o 
                        BrSafra e tenha uma Safra tranquila, sem sustos.
                      </p>
                    </div>
                  </div>
                </div>
                <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">

              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3  font-semibold">Aquisição de Produtos, Sementes e insumos</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  Lance as entradas de seus produtos de forma dinâmica, direcionando-o ao seu Estoque, 
                  Aplicação na Fazenda, ou manutenções com maquinários, agregando valor na sua safra, maquinários e talhões.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://textexpander.com/wp-content/uploads/2020/06/business-silos-1-1200x600.png"
                />
              </div>
            </div>
          </div>
        </section>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
