import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Profile() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://th.bing.com/th/id/R.e62ad2eb67f05e286fb500b539d6896f?rik=bYJqup0IQgznZg&riu=http%3a%2f%2fagrosolsementes.com.br%2fwp-content%2fuploads%2f2016%2f06%2fcultivares_bg.jpg&ehk=jDLY3zYcZK2wvQTORJxGppzEcpb8qxj%2bvhqcnPxJ9js%3d&risl=&pid=ImgRaw&r=0')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("assets/img/mais.png").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0"></div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  Demais soluções
                  </h3>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                  <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">SOLUÇÕES PERSONALIZADAS</h6>
                    <br/>
                    <a  className="text-blueGray-500" > <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Desenvolvimento de soluções personalizadas  </a>
                     <br/>
                    <a  className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Resultados em seu smartphone  </a>
                     <br/>
                    <a  className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Integração e automação de processos </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fa fa-laptop"></i>
                    </div>
                    <h6 className="text-xl font-semibold">DESENVOLVIMENTO WEB</h6>
                    <br/>
                    <a  className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Desenvolvimento de sites institucionais  </a>
                    <br/>
                    <a  className="text-blueGray-500">  <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Painel de resultados on-line  </a>
                    <br/>
                    <a   className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Aplicações personalizadas  </a>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h6 className="text-xl font-semibold">OUTRAS SOLUÇÕES</h6>
                    <br/>
                    <a  className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Controle imobiliário  </a>
                    <br/>
                    <a  className="text-blueGray-500">  <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Gestão comercial  </a>
                    <br/>
                    <a   className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Sistema de frente de caixa </a>
                    <br/>
                    <a   className="text-blueGray-500"> <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i> Desenvolvimento de módulos específicos para o seu negócio </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

        </section>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
