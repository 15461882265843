import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Profile() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage:
            "url('https://img.freepik.com/fotos-gratis/silos-agricolas-exterior-do-edificio_146671-19099.jpg?t=st=1648479809~exp=1648480409~hmac=b93a7409af859b4491f4ec75f6efea80a5ef2605ca17cadbdcd3d52f8f400c65&w=1060')", }} >
            <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
          </div>
          <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" style={{ transform: "translateZ(0)" }} >
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"
              viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img alt="..." src={require("assets/img/armazenar.png").default}className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0"> <div className=" text-blueGray-400 font-bold uppercase"></div> </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8"> </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  Silos e Armazéns de Grãos
                  </h3>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <div className="text-align: left text-blueGray-400 font-bold uppercase">
                        Silo Virtual
                      </div>
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        O silo virtual é uma forma de acompanhar o contrato do agricultor com seu armazém de forma rápida e prática, 
                        auxiliando no controle geral da fazenda.
                      </p>

                    </div>
                    <div className="text-center">
                      <iframe  width="560" height="315" src="https://www.youtube.com/embed/ehIXmeJwoOE" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"allowfullscreen > 
                      </iframe>
                    </div>
                  </div>
                </div>
                <section className="relative py-20">
                  <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                    style={{ transform: "translateZ(0)" }} >
                    <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"
                      viewBox="0 0 2560 100" x="0" y="0" >
                      <polygon className="text-white fill-current" points="2560 0 2560 100 0 100" ></polygon>
                    </svg>
                  </div>
                  <div className="container mx-auto px-4">
                    <div className="items-center flex flex-wrap">

                      <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                        <div className="md:pr-12">
                          <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                            <i className="fas fa-rocket text-xl"></i>
                          </div>
                          <h3 className="text-3  font-semibold">Tabela de descontos</h3>
                          <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                            Crie suas tabelas de descontos por produto de forma dinâmica, permitindo assim um melhor controle e 
                            confiabilidade para as entradas e saídas do seu armazém.
                          </p>
                        </div>
                      </div>
                      <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                        <img alt="..." className="max-w-full rounded-lg shadow-lg" src="https://textexpander.com/wp-content/uploads/2020/06/business-silos-1-1200x600.png"/>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
